<template>
  <div
    id="competition-type3"
    :class="data.block"
    class="competition h-295px my-2"
  >
    <!-- 遊戲類型 -->
    <swiper
      ref="competitionTab"
      :options="tabSwiperOption"
      class="tabSwiper px-4 mb-2 h-44px"
      @slideChange="onSlide"
    >
      <swiper-slide
        v-for="(competition, index) in filterCompetitionItem"
        :key="competition._id"
      >
        <div
          class="cursor-pointer primary d-flex rounded align-center justify-end h-100"
        >
          <!-- IMG -->
          <div class="ml-3">
            <v-img
              max-width="20"
              max-height="20"
              :src="competition[0].game_logo"
            />
          </div>
          <!-- NAME -->
          <div class="subtitle-2 icon--text w-100 px-4 text-center">
            {{ index }}
          </div>
        </div>
      </swiper-slide>
    </swiper>

    <!-- 列表 -->
    <div class="px-4">
      <swiper
        ref="competitionItem"
        class="swiper h-247px rounded box-shadow"
        :options="competitionSwiperOption"
        @slideChange="competitionItemOnSlide"
      >
        <swiper-slide
          v-for="competition in filterCompetitionItem"
          :key="competition._id"
          class="card1 overflow-y-auto"
        >
          <!-- 列表 -->
          <div
            v-for="item in competition"
            :key="item._id"
          >
            <!-- 比賽內容 -->
            <div class="competion__content d-flex justify-space-between align-center px-4 h-68px">
              <!-- 比賽時間-->
              <div class="competion__content-num w-40 text-center">
                <div class="caption comment--text  font-weight-bold">
                  {{ item.start_datetime.split(' ')[0] }}
                </div>
                <div class="caption  secondary--text  font-weight-bold">
                  {{ item.start_datetime.split(' ')[1].slice(0,5) }}
                </div>
              </div>

              <!-- 主場 vs 客場 -->
              <div class="competion__content-away w-100 d-flex justify-center align-center text-right">
                <!-- 主場 -->
                <v-img
                  max-width="32"
                  max-height="32"
                  :src="item.home.team_logo"
                />

                <div class="primary--text font-weight-bold px-10">
                  {{ !item.match_scoreline ? 'VS' : item.match_scoreline }}
                </div>

                <!-- 客場 -->
                <v-img
                  max-width="32"
                  max-height="32"
                  :src="item.away.team_logo"
                />
              </div>
            </div>
            <v-divider class="divider mx-4" />
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data: () => ({}),

  // 賽事資料
  computed: {
    ...mapGetters(['baseImgUrl', 'theme']),

    /**
     * 所有賽程資料
     * @date 2021-07-19
     * @returns {array}
     */
    competitions() {
      return this.data.data[this.data.type]
    },

    /**
     * 賽程資料類別名稱 for tab
     * @date 2021-07-19
     * @returns {array}
     */
    filterCompetitionName() {
      const competionNameAry = []
      this.data.data[this.data.type].forEach(item => {
        if (competionNameAry.includes(item.competition_name)) return false
        competionNameAry.push(item.competition_name)
      })
      return competionNameAry
    },

    /**
     * 依類別篩選 類別之下的賽程
     * @date 2021-07-19
     * @returns {object}
     */
    filterCompetitionItem() {
      const competitionItems = {}
      this.filterCompetitionName.forEach(name => {
        competitionItems[name] = this.competitions.filter(item => item.competition_name === name)
      })
      return competitionItems
    },

    /**
     * 賽事類別 tab swiper slide 設置
     * @date 2021-07-19
     * @returns {object}
     */
    tabSwiperOption() {
      return {
        autoplay: {
          delay: 3000,
        },
        slidesPerView: 1.1,
        spaceBetween: 8,
        centeredSlides: true,
        centeredSlidesBounds: true,
      }
    },

    /**
     * 賽事項目 swiper 設置
     * @date 2021-07-19
     * @returns {object}
     */
    competitionSwiperOption() {
      return {
        autoplay: {
          delay: 3000,
        },
      }
    },

    // item background
    bg() {
      return {
        light: { background: 'rgba(206, 206, 206, 0.12)' },
        dark: { background: 'rgba(0, 0, 0, 0.25)' },
      }
    },

    customPaginationColor() {
      const themePrimaryColor = this.$vuetify.theme.themes[this.theme]?.primary
      // 目前依 primary 顯色
      return {
        '--swiper-theme-color': themePrimaryColor || 'white',
      }
    },

  },

  methods: {
    /**
     * 賽程類別 tab 滑動執行
     * @date 2021-07-19
     */
    onSlide() {
      this.$refs.competitionItem.$swiper.slideTo(this.$refs.competitionTab.$swiper.activeIndex, 500)
    },

    /**
     * 賽事項目 滑動執行
     * @date 2021-07-19
     */
    competitionItemOnSlide() {
      this.$refs.competitionTab.$swiper.slideTo(this.$refs.competitionItem.$swiper.activeIndex, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
.h-44px {
	height: 44px;
}

.h-295px {
	height: 295px;
}

.h-247px {
	height: 247px;
}

.h-68px {
	height: 68px;
}

.w-40 {
	width: 40%;
}

.competion__content {
	&-home,
	&-away {
		.caption {
			line-height: 14px;
		}
	}
}

.swiper {
	.swiper-pagination {
		top: 4px;
		margin-left: -16px;
		text-align: right;
	}
}

.box-shadow {
	box-shadow: 5px 5px 20px rgba(0, 0, 0, .2);
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "competition h-295px my-2",
      class: _vm.data.block,
      attrs: { id: "competition-type3" },
    },
    [
      _c(
        "swiper",
        {
          ref: "competitionTab",
          staticClass: "tabSwiper px-4 mb-2 h-44px",
          attrs: { options: _vm.tabSwiperOption },
          on: { slideChange: _vm.onSlide },
        },
        _vm._l(_vm.filterCompetitionItem, function (competition, index) {
          return _c("swiper-slide", { key: competition._id }, [
            _c(
              "div",
              {
                staticClass:
                  "cursor-pointer primary d-flex rounded align-center justify-end h-100",
              },
              [
                _c(
                  "div",
                  { staticClass: "ml-3" },
                  [
                    _c("v-img", {
                      attrs: {
                        "max-width": "20",
                        "max-height": "20",
                        src: competition[0].game_logo,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "subtitle-2 icon--text w-100 px-4 text-center",
                  },
                  [_vm._v(" " + _vm._s(index) + " ")]
                ),
              ]
            ),
          ])
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "px-4" },
        [
          _c(
            "swiper",
            {
              ref: "competitionItem",
              staticClass: "swiper h-247px rounded box-shadow",
              attrs: { options: _vm.competitionSwiperOption },
              on: { slideChange: _vm.competitionItemOnSlide },
            },
            _vm._l(_vm.filterCompetitionItem, function (competition) {
              return _c(
                "swiper-slide",
                { key: competition._id, staticClass: "card1 overflow-y-auto" },
                _vm._l(competition, function (item) {
                  return _c(
                    "div",
                    { key: item._id },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "competion__content d-flex justify-space-between align-center px-4 h-68px",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "competion__content-num w-40 text-center",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "caption comment--text font-weight-bold",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.start_datetime.split(" ")[0]
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "caption secondary--text font-weight-bold",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.start_datetime
                                          .split(" ")[1]
                                          .slice(0, 5)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "competion__content-away w-100 d-flex justify-center align-center text-right",
                            },
                            [
                              _c("v-img", {
                                attrs: {
                                  "max-width": "32",
                                  "max-height": "32",
                                  src: item.home.team_logo,
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "primary--text font-weight-bold px-10",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        !item.match_scoreline
                                          ? "VS"
                                          : item.match_scoreline
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("v-img", {
                                attrs: {
                                  "max-width": "32",
                                  "max-height": "32",
                                  src: item.away.team_logo,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("v-divider", { staticClass: "divider mx-4" }),
                    ],
                    1
                  )
                }),
                0
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }